import React from 'react'

export const TextAreaQuestion = ({ index, question, itemLabel, maxCharsTextArea }) => {
    return (
        <>
            <textarea className="form-control"
                data-hj-allow
                maxLength={question?.max_length && question?.max_length > 0 && question?.max_length < 100 ? question.max_length : maxCharsTextArea}
                name={`textArea${(index + 1)}${(question.id)}`}
                id={`textArea${(index + 1)}${(question.id)}`}
                rows="3"
            />
        </>
    )
}
