import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Tooltip } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react'
import PaletteSharpIcon from '@mui/icons-material/PaletteSharp';
import CloseIcon from '@mui/icons-material/Close';
import StylesContext from './SytlesContextViewSurvey';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import { FileInput } from './FileInput';
import { FormattedMessage, useIntl } from 'react-intl';

export const DrawerViewSurvey = () => {
    const {
        btnColorBackground,
        setBtnColorBackground,
        btnColorText,
        setBtnColorText,
        surveySubtitleColor,
        setSurveySubtitleColor
    } = useContext(StylesContext);
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const btnColorBackgroundInputRef = useRef(null);
    const btnColorTextInputRef = useRef(null);
    const surveyTitleInputRef = useRef(null);
    const surveySubtitleInputRef = useRef(null);

    useEffect(() => {
        btnColorBackgroundInputRef.current.value = btnColorBackground;
        btnColorTextInputRef.current.value = btnColorText;
        surveySubtitleInputRef.current.value = surveySubtitleColor;
    }, [btnColorBackground, btnColorText, surveySubtitleColor]);

    useEffect(() => {
        const handleBlur = () => {
            if (surveySubtitleInputRef.current) {
                setSurveySubtitleColor(prev => {
                    if (surveySubtitleInputRef.current.value !== prev) {
                        //scroll to the top of the page
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth"
                        });
                    }
                    return surveySubtitleInputRef.current.value
                });
            }
            if (btnColorBackgroundInputRef.current) {
                setBtnColorBackground(prev => {
                    if (btnColorBackgroundInputRef.current.value !== prev) {
                        //scroll to the bottom of the page
                        window.scrollTo({
                            top: document.body.scrollHeight,
                            behavior: "smooth"
                        });
                    }
                    return btnColorBackgroundInputRef.current.value
                });
            }
            if (btnColorTextInputRef.current) {
                setBtnColorText(prev => {
                    if (btnColorTextInputRef.current.value !== prev) {
                        //scroll to the bottom of the page
                        window.scrollTo({
                            top: document.body.scrollHeight,
                            behavior: "smooth"
                        });
                    }
                    return btnColorTextInputRef.current.value
                });
            }
        };

        const surveyTitleInput = surveyTitleInputRef.current;
        const surveySubtitleInput = surveySubtitleInputRef.current;
        const btnColorBackgroundInput = btnColorBackgroundInputRef.current;
        const btnColorTextInput = btnColorTextInputRef.current;
        if (surveyTitleInput) {
            surveyTitleInput.addEventListener('blur', handleBlur);
        }
        if (surveySubtitleInput) {
            surveySubtitleInput.addEventListener('blur', handleBlur);
        }
        if (btnColorBackgroundInput) {
            btnColorBackgroundInput.addEventListener('blur', handleBlur);
        }
        if (btnColorTextInput) {
            btnColorTextInput.addEventListener('blur', handleBlur);
        }

        return () => {
            if (surveyTitleInput) {
                surveyTitleInput.removeEventListener('blur', handleBlur);
            }
            if (surveySubtitleInput) {
                surveySubtitleInput.removeEventListener('blur', handleBlur);
            }
            if (btnColorBackgroundInput) {
                btnColorBackgroundInput.removeEventListener('blur', handleBlur);
            }
            if (btnColorTextInput) {
                btnColorTextInput.removeEventListener('blur', handleBlur);
            }
        };
        // eslint-disable-next-line
    }, []);

    const DrawerList = (
        <Box sx={{ width: 250, }} role="presentation">
            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", padding: "10px" }}>
                <h4 style={{ marginTop: "6px" }}><PaletteSharpIcon />&nbsp;
                    <FormattedMessage
                        id="styles"
                    />
                </h4>
                {/* close button on the right */}
                <IconButton
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => setOpen(false)}
                    style={{ marginLeft: "auto" }}
                    color="inherit"
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <Divider />
            <List>
                <ListItem disablePadding >
                    <ListItemButton >
                        <FileInput />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding >
                    <label htmlFor="subtitleFontColor" style={{ width: "100%" }}>
                        <ListItemButton >
                            <TextFormatIcon />&nbsp;&nbsp;
                            <ListItemText
                                primary={
                                    <FormattedMessage
                                        id="styles.header.subtitle"
                                    />
                                }
                            />
                            <input
                                ref={surveySubtitleInputRef}
                                type="color"
                                // onMouseUp={handleMouseUp}
                                className='CircleColorPicker' id="subtitleFontColor"
                            />
                        </ListItemButton>
                    </label>
                </ListItem>
                <ListItem disablePadding >
                    <label htmlFor="btn-background" style={{ width: "100%" }}>
                        <ListItemButton >
                            <FormatPaintIcon /> &nbsp;&nbsp;
                            <ListItemText
                                primary={
                                    <FormattedMessage
                                        id="styles.btn.background.color"
                                    />
                                }
                            />
                            <input
                                ref={btnColorBackgroundInputRef}
                                type="color"
                                className='CircleColorPicker'
                                id="btn-background"
                            />
                        </ListItemButton>
                    </label>
                </ListItem>
                <ListItem disablePadding >
                    <label htmlFor="btn-text-color" style={{ width: "100%" }}>
                        <ListItemButton >
                            <FormatShapesIcon />&nbsp;&nbsp;
                            <ListItemText
                                primary={
                                    <FormattedMessage
                                        id="styles.btn.text.color"
                                    />
                                }
                            />
                            <input
                                ref={btnColorTextInputRef}
                                type="color"
                                className='CircleColorPicker'
                                id="btn-text-color"
                            />
                        </ListItemButton>
                    </label>
                </ListItem>
            </List>
            <Divider />
            <List>
                {/* {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))} */}
            </List>
        </Box>
    );

    return (
        <div>
            <Tooltip title={intl.formatMessage({ id: "survey.edit.styles.title" })} arrow>
                <IconButton
                    size="large"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => setOpen(!open)}
                    color="inherit"
                >
                    <PaletteSharpIcon />
                </IconButton>

            </Tooltip>
            <Drawer
                open={open}
                anchor={"right"}
                onClose={() => setOpen(false)}
                hideBackdrop={true}
                PaperProps={{
                    sx: {
                        top: "66px",
                        background: "#03989e",
                        color: "white",
                        boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0.5)",
                    },
                }}
                ModalProps={{
                    //Allow scroll when drawer is open
                    disableScrollLock: true,
                    //allow click outside when drawer is open
                }}
                variant='persistent'
            >
                {DrawerList}
            </Drawer>
        </div>
    )
}
