import React, { useContext } from 'react'
import StylesContext from '../UtilsComponents/SytlesContextViewSurvey';
import CreateIcon from '@mui/icons-material/Create';
import { FormattedMessage } from 'react-intl';

export const SurveyTitles = ({ title, subtitle }) => {
    const {
        surveySubtitleColor
    } = useContext(StylesContext);
    const [surveySubtitle, setSurveySubtitle] = React.useState(subtitle)

    const autoGrow = (element) => {
        element.style.height = "5px";
        element.style.height = (element.scrollHeight) + "px";
    };
    const handleSubTitleChange = (e) => {
        setSurveySubtitle(e.target.value);
        autoGrow(e.target);
    }
    return (
        <>
            <h5 className='text-center'>
                <b>
                    <CreateIcon /> <FormattedMessage
                        id="styles.click.to.change.title"
                    />
                </b>
            </h5>
            <h5 className="reduceTitle mt-3">
                <textarea
                    id="input-subtitle"
                    className='titleInputSmall text-center'
                    type="text"
                    style={{ color: surveySubtitleColor }}
                    maxLength={200}
                    spellCheck="false"
                    value={surveySubtitle}
                    onChange={handleSubTitleChange}
                />
            </h5>
        </>
    )
}
