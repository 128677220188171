import { CardActions, CardContent, Typography } from '@mui/material'
import React from 'react'
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import { axiosPrivate } from '../../../api/axios';
import { useQuery } from '@tanstack/react-query';
import useAuth from '../../../CustomHooks/useAuth';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MinimizeOutlinedIcon from '@mui/icons-material/MinimizeOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormattedMessage } from 'react-intl';

export const InsightsSurvey = () => {
    const surveyId = new URLSearchParams(window.location.search).get("survey");
    const { defaultCustomer } = useAuth();
    const [updateDate, setUpdateDate] = React.useState(null);
    const { data: insights, error: errorInsights, isLoading: isLoadingInsights } = useQuery({
        queryKey: ["insights", surveyId],
        queryFn: async () => {
            const response = await axiosPrivate.get(`dashboard/surveyinsights/${surveyId}?customer=${defaultCustomer}`);
            setUpdateDate(response.data.updateDate);
            delete response.data.updateDate;
            return response.data;
        },
        refetchOnWindowFocus: false,
        retry: false,
    });

    //#region Get dates
    // Obtenemos la fecha actual de Colombia
    const currentColombiaDate = updateDate ? new Date(updateDate): new Date();
    const options = { timeZone: "America/Bogota", year: 'numeric', month: 'long', day: 'numeric' };

    // Convertimos la fecha actual a la zona horaria de Colombia
    const colombiaTime = new Date(currentColombiaDate.toLocaleString("en-US", options));

    // Calculamos la fecha de 30 días antes
    const colombiaStartTime = new Date(colombiaTime);
    colombiaStartTime.setDate(colombiaTime.getDate() - 30);

    // Formateamos las dos fechas en el formato de Colombia
    const startDate = colombiaStartTime.toLocaleDateString("es-CO", options);
    const endDate = colombiaTime.toLocaleDateString("es-CO", options);
    //#endregion

    //#region Custom Names
    const sentiments = {
        1: {
            value: "Negativo",
            icon: <WarningAmberIcon />,
            color: "#f9e3e3"
        },
        2: {
            value: "Neutro",
            icon: <InfoOutlinedIcon />,
            color: "#fef4da"
        },
        3: {
            value: "Positivo",
            icon: <ThumbUpOffAltIcon />,
            color: "#d1f4ca"
        },
        4: {
            value: "No clasificado",
            icon: <MinimizeOutlinedIcon />,
            color: "white"
        }
    }

    const titleName = {
        textBox: {
            name: "Preguntas campo de texto",
            icon: <EditOutlinedIcon />
        },
        radio: {
            name: "Preguntas de selección única",
            icon: <CheckCircleOutlinedIcon />
        },
        check: {
            name: "Preguntas de selección múltiple",
            icon: <CheckBoxOutlinedIcon />
        },
        textArea: {
            name: "Preguntas área de texto",
            icon: <EditNoteIcon />
        },
        general: {
            name: "Resumen general",
            icon: <DonutSmallOutlinedIcon />
        }
    }
    //#endregion Custom Names

    //#region Renders
    if (isLoadingInsights) {
        return <div className='text-center'><FormattedMessage id="loading.msg" /></div>
    }
    if (errorInsights?.status === 404) {
        return <div className='text-center'>
            {/* <h3 >
                Insights
            </h3> */}
            No hay insights para mostrar
        </div>
    }
    if (errorInsights) {
        return <div>
            {/* <h3 className='text-center'>
                Insights
            </h3> */}
            <p style={{ textAlign: "justify", fontSize: "16px" }} >
                Ha ocurrido un error al cargar los insights.
            </p>
        </div>
    }

    return (
        <>
            <h3 className='text-center'>
                Insights
            </h3>
            <p style={{ textAlign: "justify", fontSize: "16px" }} >
                Los insights mostrarán preferencias comunes y patrones de elección en preguntas de selección única y múltiple, así como tendencias cualitativas, palabras clave y análisis de sentimientos en campos y áreas de texto. Las respuestas cortas ofrecen datos concretos, mientras que las más extensas permiten identificar ideas clave o temas recurrentes. Además, estos insights pueden ayudar a notar ciertas falencias, y están representados con colores para denotar aspectos positivos, negativos y neutros.
                <span className='mb-3' style={{ display: "block", fontSize: "13px", marginTop: "8px" }}>
                    Esta información es tomada con encuestas realizadas entre los días <strong>{startDate}</strong> y el <strong>{endDate}</strong>.
                </span>
            </p>
            {Object.entries(insights).map(([key, value]) => {
                const BoldText = value?.text?.replace(/\*\*/g, '*');
                const ShareText = `*${titleName[key]?.name}* \n\n${BoldText} `;
                const urlMessage = encodeURIComponent(ShareText);
                const copyToClipboard = () => {
                    navigator.clipboard.writeText(ShareText).then(() => {
                        alert('Copied to clipboard!');
                    }).catch(err => {
                        console.error('Failed to copy: ', err);
                    });
                };
                if (value?.text?.length === 0 || key === 'updateDate') {
                    return null;
                }
                const parseText = (inputText) => {
                    // Split the text into parts by newlines
                    return inputText?.split('\n').map((paragraph, index) => {
                        // Replace **bold** with JSX <strong> tags
                        const parsedParagraph = paragraph.split(/(\*.*?\*)/g).map((part, i) => {
                            if (part.startsWith('*') && part.endsWith('*')) {
                                // Remove the ** and return as a <strong> tag
                                return <strong key={i}>{part.slice(1, -1)}</strong>;
                            }
                            return part;
                        });
                        return <p key={index} style={{ lineHeight: "23px", textAlign: "justify", fontSize: "15px" }}>{parsedParagraph}</p>; // Return each paragraph in a <p> tag
                    });
                };
                return (
                    <div className='mb-4' style={{ boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.2)", width: "100%", borderRadius: "10px", backgroundColor: sentiments[value?.sentiment]?.color }} key={key}>
                        <CardContent>
                            <Typography className='mb-2' variant="h5" component="div">
                                {titleName[key]?.icon} {titleName[key]?.name}
                                <span style={{ float: 'right', fontSize: "18px" }}>
                                    {sentiments[value?.sentiment]?.value} {sentiments[value?.sentiment]?.icon}
                                </span>
                            </Typography>
                            <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>
                            </Typography>
                            {parseText(BoldText)}
                        </CardContent>
                        <CardActions>
                            <div className='container'>
                                Compartir &nbsp;
                                <a className='' size="small" target='_blank' href={`https://api.whatsapp.com/send?text=${urlMessage}`} rel='noreferrer'>
                                    <i className='fa fa-whatsapp shareicon' />
                                </a>
                                <a className='mx-3' size="small " target='_blank' href={`mailto:?subject=¡Insights 7Bytes!&body=${urlMessage}`} rel='noreferrer'>
                                    <i className='fa fa-envelope shareicon' />
                                </a>
                                <i className='fa fa-copy shareicon' style={{ cursor: "pointer" }} onClick={copyToClipboard} />
                            </div>
                        </CardActions>
                    </div>
                )
            })}
        </>
    )
    //#endregion Renders
}
