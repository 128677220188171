import { Box, Skeleton } from '@mui/material'
import { useSurveyContext } from '../Context/SurveyCustomHooks/useSurveyContext';

export const SurveySkeleton = () => {
    const {
        surveyBackgroundColor: backgroundColor = 'whitesmoke'
    } = useSurveyContext() || {};

    return (
        <Box sx={{ backgroundColor, height: "100vh" }} >
            <div className="center" align="center">
                <em style={{ visibility: "hidden" }}>title</em>
                <Skeleton animation="wave" className=' ' variant="rounded" height={50} width={120} />
            </div>
            <br />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center', // Opcional: centra verticalmente los elementos
                }}
            >
                <div style={{ flex: 1, margin: '0 10px' }}>
                    <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                </div>
                <div style={{ flex: 10, margin: '0 10px' }}>
                    <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                </div>
                {/* <div style={{ flex: 1, margin: '0 10px' }}>
                    <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                </div> */}
            </div>
            <div className='container text-center' style={{ marginTop: "10vh" }}>
                <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
                <br />
                <Skeleton animation="wave" className='' variant="rounded" height={160} />
            </div>
            <div
                className="divmysurveybtn"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Skeleton animation="wave" className='mysurveybtn center' variant="rounded" height={48} />
            </div>
        </Box>
    )
}
