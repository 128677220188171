import React, { useContext, useEffect, useState } from 'react'
import { RadioQuestion } from './RadioQuestion'
import { RadioAnswer } from './RadioAnswer'
import { CheckQuestion } from './CheckQuestion'
import { TextBoxQuestion } from './TextBoxQuestion'
import { TextAreaQuestion } from './TextAreaQuestion'
import { CheckAnswer } from './CheckAnswer'
import { FormattedMessage, useIntl } from 'react-intl'
import { SelectCheckQuestion } from './SelectCheckQuestion'
import { SelectRadioQuestion } from './SelectRadioQuestion'
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, LinearProgress, Tooltip } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import useAxiosPrivate from '../../../CustomHooks/useAxiosPrivate'
import { SnackBarComponent } from '../../UtilsComponents/SnackBarComponent'
import { TransitionDown, topCenterPosition } from '../../UtilsComponents/SnackBarTransitions'
import useAuth from '../../../CustomHooks/useAuth'
import { AndroidSwitch } from '../../ReuseComponents/AndroidSwitch '
import { QuestionsMarksInfo } from './QuestionsMarksInfo'
import StylesContext from '../UtilsComponents/SytlesContextViewSurvey'

export const ViewQuestion = ({
    questions,
    subQuestions,
    openId, language,
    setOpenModal, setEditQuestion,
    isRefetchingQuestions,
    validateQuestionLimitation,
    maxCharsTextBox,
    maxCharsTextArea
}) => {
    const axiosPrivate = useAxiosPrivate();
    const { defaultCustomer, isViewRole } = useAuth();
    const queryClient = useQueryClient();
    const query = new URLSearchParams(window.location.search);
    const survey = query.get("survey");
    const [color, setColor] = useState("success");
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [msg, setMsg] = useState('');
    const [deleteId, setDeleteId] = useState(null);
    const [checkDelete, setCheckDelete] = useState(false);
    const intl = useIntl();

    const {
        btnColorBackground,
        btnColorText
    } = useContext(StylesContext);

    const getSubQuestion = (subItems = [], openQuestionId) => {
        const newQuestions = subItems.map((item) => {
            const [id] = item;
            const question = subQuestions.find((question) => question.id === id.toString());
            const values = subItems.filter((item) => item[0] === id).map((item) => item[1]);
            question["openBy" + openQuestionId] = values;
            return question;
        });
        // //remove duplicates

        // return newQuestions;
        //remove duplicates by id
        const unique = [...new Set(newQuestions.map(item => item.id))];
        const uniqueQuestions = unique.map((id) => {
            const question = newQuestions.find((question) => question.id === id);
            return question;
        });
        return uniqueQuestions;
    }
    const updateSurveyMutation = useMutation({
        mutationFn: async ({ id }) => {
            const response = await axiosPrivate.delete(`question/actions/${id}?customer=${defaultCustomer}`)
            return response.data
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(["surveyQuestions", survey], { exact: true });
            setCheckDelete(true);
            setColor("success");
            setMsg(intl.formatMessage({ id: "edit.survey.msg.question.delete.success" }));
        },
        onError: (error) => {
            setOpenSnackBar(true);
            setColor("error");
            setMsg(intl.formatMessage({ id: "edit.survey.msg.question.delete.error" }));
            setDeleteId(null);
            console.error(error);
        }
    });

    useEffect(() => {
        if (checkDelete && !isRefetchingQuestions) {
            setOpenSnackBar(true);
            setCheckDelete(false);
            setDeleteId(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRefetchingQuestions]);

    const handleDelete = (e) => {
        let id = e.target.id;
        if (e.target.id === "") {
            //rise to the parent
            id = e.target.parentElement.id;
        }
        if (window.confirm(intl.formatMessage({ id: "edit.survey.msg.question.delete" }))) {
            updateSurveyMutation.mutate({ id });
            setDeleteId(id);
        }
    }

    return (
        <>
            <SnackBarComponent
                position={topCenterPosition}
                transition={TransitionDown}
                handleClose={(event, reason) => {
                    if (reason === 'clickaway') {
                        return;
                    }
                    setOpenSnackBar(false);
                }}
                time={4000}
                openSnackBar={openSnackBar}
                color={color}
                msg={msg}
            />
            {questions.map((question, index) => {
                let itemLabel, optionLabel;
                if (language !== "es") {
                    itemLabel = "item_label_" + language;
                    optionLabel = "options_" + language;
                    if (!question[itemLabel]) {
                        itemLabel = "item_label";
                    };
                    if (question[optionLabel][0] === null) {
                        optionLabel = "options";
                    }
                } else {
                    itemLabel = "item_label";
                    optionLabel = "options";
                }
                const questionLabel = `${(index + 1)}. ${question[itemLabel]}`;
                return (
                    <div className="row mt-4" key={index}>
                        <div className="col-sm-12">
                            <div className="card shadow">
                                <div className="card-body" >
                                    <div className="form-group" >
                                        {question["openBy" + openId] ?
                                            <div className=''>
                                                <div className='row'>
                                                    <div className='col-md-7 col-sm-6'>
                                                        <h6>
                                                            <FormattedMessage id="survey.view.open.by" /> {
                                                                question["openBy" + openId].map((item, index) => {
                                                                    return (
                                                                        <span key={index}>
                                                                            {item}
                                                                            {index < question["openBy" + openId].length - 1 && ", "}
                                                                        </span>
                                                                    )
                                                                })
                                                            }
                                                        </h6>
                                                    </div>
                                                    {!isViewRole &&
                                                        <div className='col-md-5 col-sm-6' >
                                                            <Box sx={{ '& > :not(style)': { mr: 1 } }} style={{ float: "right" }}>
                                                                <Fab className='editButton' size="small" color="primary" aria-label="add">
                                                                    <AddIcon className='editButtonIcon' />
                                                                </Fab>
                                                                <Fab className='editButton' size="small" color='warning' onClick={() => {
                                                                    setEditQuestion(question);
                                                                    setOpenModal(true);
                                                                }} aria-label="edit">
                                                                    <EditIcon className='editButtonIcon' />
                                                                </Fab>
                                                                <Fab className='editButton' size="small" color="error" aria-label="like">
                                                                    <DeleteOutlineIcon className='editButtonIcon' />
                                                                </Fab>
                                                            </Box>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            !isViewRole &&
                                            <div className=''>
                                                <div className='row'>
                                                    <div className='col-' >
                                                        <Box sx={{ '& > :not(style)': { mr: 1 } }} style={{ float: "left" }}>
                                                            <em>
                                                                <FormattedMessage
                                                                    id="edit.survey.options"
                                                                    defaultMessage={`Opciones de edicion`}
                                                                />
                                                            </em>
                                                            <Fab className='editButton' size="small" color="primary" aria-label="add" onClick={() => {
                                                                let newEmptyQuestion = []
                                                                Object.keys(question).forEach((key) => {
                                                                    if (key === 'item_type') {
                                                                        newEmptyQuestion[key] = "Text Area";
                                                                    } else if (key === 'question_type') {
                                                                        newEmptyQuestion[key] = 3;
                                                                    } else if (key === 'subtitle') {
                                                                        newEmptyQuestion[key] = '';
                                                                    }else if (key === 'enabled') {
                                                                        newEmptyQuestion[key] = true;
                                                                    } else if (key === 'item_order') {
                                                                        newEmptyQuestion[key] = '0';
                                                                        if (typeof question[key] === 'string') {
                                                                            newEmptyQuestion[key] = parseInt(question[key]) + 1;
                                                                        }
                                                                    } else if (typeof question[key] === 'string') {
                                                                        newEmptyQuestion[key] = "";
                                                                    } else if (typeof question[key] === 'number') {
                                                                        newEmptyQuestion[key] = 0;
                                                                    } else if (typeof question[key] === 'boolean') {
                                                                        newEmptyQuestion[key] = false;
                                                                    } else if (question[key] === null) {
                                                                        newEmptyQuestion[key] = null;
                                                                    } else if (typeof question[key] === 'object') {
                                                                        newEmptyQuestion[key] = [];
                                                                    }
                                                                    
                                                                });
                                                                if (validateQuestionLimitation()) return;
                                                                newEmptyQuestion['create'] = true;
                                                                setEditQuestion(newEmptyQuestion);
                                                                setOpenModal(true);
                                                            }}>
                                                                <AddIcon className='editButtonIcon' />
                                                            </Fab>
                                                            <Fab className='editButton' size="small" color='warning' onClick={() => {
                                                                setEditQuestion(question);
                                                                setOpenModal(true);
                                                            }} aria-label="edit">
                                                                <EditIcon className='editButtonIcon' />
                                                            </Fab>
                                                            <Fab className='editButton' id={question?.id} size="small" color="error" aria-label="like" onClick={handleDelete}>
                                                                <DeleteOutlineIcon className='editButtonIcon' id={question?.id} />
                                                            </Fab>
                                                        </Box>
                                                        <div className='questionTypeFromControl' style={{ marginLeft: "10px" }}>
                                                            {/* TODO -- Cambiar para que no vuelva cargar cada vez que haya un nuevo renderizado -- */}
                                                            <AndroidSwitch
                                                                id={"enabled" + index}
                                                                label={intl.formatMessage({ id: "edit.survey.label.enabled" })}
                                                                key={"enabled" + index}
                                                                forTooltip={intl.formatMessage({ id: "edit.survey.label.enabled.tooltip" })}
                                                                checked={question?.enabled}
                                                                idNumber={question?.id}
                                                            />
                                                            <AndroidSwitch
                                                                id={"required" + index}
                                                                label={intl.formatMessage({ id: "edit.survey.label.required" })}
                                                                key={"required" + index}
                                                                forTooltip={intl.formatMessage({ id: "edit.survey.label.required.tooltip" })}
                                                                checked={question?.required}
                                                                idNumber={question?.id}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    deleteId === question?.id ?
                                                        <Box className='my-2' sx={{ width: '100%' }}>
                                                            <LinearProgress />
                                                        </Box>
                                                        : <hr />
                                                }
                                            </div>
                                        }
                                        <div>
                                            <label className="form-label " htmlFor={`textArea${(index + 1)}${(question.id)}`}>
                                                {`${(index + 1)}. ${question[itemLabel]}`}
                                                <QuestionsMarksInfo required={question.required} type={question.question_type} />
                                            </label>
                                        </div>
                                        <div className="mb-3">
                                            <em className="form-text text-muted">
                                                {language === "es" ? question["subtitle"] : question["subtitle_" + language]}
                                            </em>
                                        </div>
                                        {question.item_type === "Radio" &&
                                            <RadioQuestion>
                                                <div className="col text-center" >
                                                    <div className="form-check-inline text-center align-top" >
                                                        <div className="col text-center">
                                                            <RadioAnswer options={question[optionLabel]} id={question["id"]} emojiPack={question["pack_name"]} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {question?.subItems &&
                                                    <ViewQuestion
                                                        questions={getSubQuestion(question.subItems, question.id)}
                                                        subQuestions={subQuestions}
                                                        openId={question.id}
                                                        subOpenId={(index + 1)}
                                                        language={language}
                                                        setEditQuestion={setEditQuestion}
                                                        setOpenModal={setOpenModal}
                                                    />
                                                }
                                            </RadioQuestion>
                                        }
                                        {question.item_type === "Select Radio" &&
                                            <SelectRadioQuestion index={index} question={question} optionLabel={optionLabel} />
                                        }
                                        {question.item_type === "Check" &&
                                            <CheckQuestion >
                                                <CheckAnswer options={question[optionLabel]} id={question["id"]} />
                                            </CheckQuestion>
                                        }
                                        {question.item_type === "Select Check" &&
                                            <SelectCheckQuestion index={index} question={question} optionLabel={optionLabel} />
                                        }
                                        {question.item_type === "Text Box" &&
                                            <TextBoxQuestion
                                                index={index}
                                                question={question}
                                                maxCharsTextBox={maxCharsTextBox}
                                            />
                                        }
                                        {question.item_type === "Text Area" &&
                                            <TextAreaQuestion
                                                index={index}
                                                question={question}
                                                maxCharsTextArea={maxCharsTextArea}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            {
                questions?.length > 0 && openId === undefined &&
                <div className="row mt-3">
                    <div className="col text-center">
                        <Tooltip title={"Boton de demostracion"} placement="top">
                            <button type="submit" style={{ backgroundColor: btnColorBackground }} className="btn btn-lg shadow">
                                <strong style={{ color: btnColorText }}>
                                    <FormattedMessage id="send.button" defaultMessage="Enviar" />
                                </strong>
                            </button>
                        </Tooltip>
                    </div>
                </div>
            }
        </>
    )
}
