import React from 'react'

export const TextBoxQuestion = ({ index, question, itemLabel, maxCharsTextBox }) => {
    return (
        <>
            <input className="form-control"
                name={`text${(index + 1)}${(question.id)}`}
                data-hj-allow
                type={question?.value_format}
                maxLength={question?.max_length && question?.max_length > 0 && question?.max_length < 100 ? question.max_length : maxCharsTextBox}
                min={question?.min_number}
                max={question?.max_number}
                id={`text${(index + 1)}${(question.id)}`}
                style={{ width: (question?.value_format === "number" || question?.value_format === "date") ? "250px" : "" }}
            />
        </>
    )
}
