import { Autocomplete, Box, Button, CircularProgress, Fade, FormControl, FormControlLabel, InputLabel, MenuItem, Modal, Select, Skeleton, Switch, TextField, Tooltip } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext, useEffect, useRef, useState } from 'react';
import { EditRadioOptions } from './EditRadioOptions';
import { EditQuestionText } from './EditQuestionText';
import { EditCheckOptions } from './EditCheckOptions';
import { green } from '@mui/material/colors';
import { red } from '@mui/material/colors';
import { SnackBarComponent } from '../../UtilsComponents/SnackBarComponent';
import { topCenterPosition, TransitionDown } from '../../UtilsComponents/SnackBarTransitions';
import { ScrollToTopComponent } from '../../UtilsComponents/ScrollToTopComponent';
import { FormattedMessage, useIntl } from 'react-intl';
import useAxiosPrivate from '../../../CustomHooks/useAxiosPrivate';
import { QUESTIONS_TYPE_LIST } from '../../../Domains/questionTypeList';
import { QUESTION_ITEM_NAME } from '../../../Domains/questionItemName';
import { MainDialogTitle } from '../../ReuseComponents/MainDialogTitle';
import { languageContext } from '../../../context/languageContext';
import { itemLabelLanguage, optionsLabelLanguage, subtitleLanguage } from '../../../Languages/TranslationsLabels';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 4,
    p: 4,
};

const LoadingInputs = () => {
    return (
        <FormControl className='' sx={{ minWidth: 120, marginRight: "10px" }}>
            <Skeleton animation="wave" height={85} />
        </FormControl>
    )
}

export const EditQuestionModal = ({ open, setOpenModal, editQuestion, customerName, validateQuestionLimitation, maxCharsTextBox, maxCharsTextArea }) => {
    // const [open, setOpen] = useState(false);
    // const handleOpen = () => setOpenModal(true);
    //get query params

    const intl = useIntl();
    const axiosPrivate = useAxiosPrivate();
    const query = new URLSearchParams(window.location.search);
    const survey = query.get("survey");
    const queryClient = useQueryClient();
    const radioOptions = ["4", "6"]
    const forRadioEmojis = [radioOptions[0]]
    const checkOptions = ["2", "5"]
    const textQuestion = ["3", "1"]
    const refreshTime = 1000 * 60 * 60 * 24;
    const acceptedEmojiAnswers = ["1", "2", "3", "4", "5"];

    const { data: questionTypes, isLoading: isLoadingQuestionTypes, isError: isErrorQuestionTypes } = useQuery({
        queryKey: ["questionTypes"],
        queryFn: async () => {
            const response = await axiosPrivate.get(`question/types?customer=${customerName}`);
            return response.data
        },
        refetchOnWindowFocus: false,
        staleTime: refreshTime,
        retry: false,
    });

    const { data: questionValuesFormat, isLoading: isLoadingQuestionValuesFormat, isError: isErrorQuestionValuesFormat } = useQuery({
        queryKey: ["questionValuesFormat"],
        queryFn: async () => {
            const response = await axiosPrivate.get(`question/valueformats?customer=${customerName}`)
            return response.data
        },
        refetchOnWindowFocus: false,
        staleTime: refreshTime,
        retry: false,
    });
    const valueFormatNames = {
        "1": intl.formatMessage({ id: "edit.survey.label.question.text.box.only.text" }),
        "2": intl.formatMessage({ id: "edit.survey.label.question.text.box.only.number" }),
        "3": intl.formatMessage({ id: "edit.survey.label.question.text.box.text.and.number" })
    };

    const { data: itemTypes, isLoading: isLoadiongItemTypes, isError: isErrorItemTypes } = useQuery({
        queryKey: ["itemTypes"],
        queryFn: async () => {
            const response = await axiosPrivate.get(`question/itemtypes?customer=${customerName}`);
            return response.data
        },
        refetchOnWindowFocus: false,
        staleTime: refreshTime,
        retry: false,
    });


    const { data: itemClasses, isLoading: isLoadingItemClasses, isError: isErrorItemClasses } = useQuery({
        queryKey: ["itemClass"],
        queryFn: async () => {
            const response = await axiosPrivate.get(`question/itemclass?customer=${customerName}`);
            return response.data
        },
        refetchOnWindowFocus: false,
        staleTime: refreshTime,
        retry: false,
    });

    const { data: emojis } = useQuery({
        queryKey: ["Emojis"],
        queryFn: async () => {
            const response = await axiosPrivate.get(`question/emojis?customer=${customerName}`)
            return response.data
        },
        refetchOnWindowFocus: false,
        staleTime: refreshTime,
    });

    const updateSurveyMutation = useMutation({
        mutationFn: async ({ changes, values }) => {
            if (!loading) {
                setSuccessBtnColor(false);
                setLoading(true);
                setFailedBtnColor(false);
            }
            const response = await axiosPrivate.put(`question/actions/${editQuestion?.id}?customer=${customerName}`, values)
            return response.data
        },
        onSuccess: (data) => {
            setSuccessBtnColor(true);
            setLoading(false);
            editQuestion.options = options;
            setOpenSnackBar(true);
            setColor("success");
            setMsg(intl.formatMessage({ id: "edit.survey.msg.question.success" }));
            queryClient.invalidateQueries(["surveyQuestions", survey], { exact: true });
            setCheckvalidationAfterBtn(false)
        },
        onError: (error) => {
            console.error(error);
            setCheckvalidationAfterBtn(false)
            if (error.response.status === 401) {
                if (error.response.data?.text === 'La pregunta ya existe') {
                    setColor("error");
                    setOpenSnackBar(true);
                    setMsg("ERROR: " + intl.formatMessage({ id: "edit.survey.msg.question.add.error.duplicate" }));
                } else if (error.response.data?.text === 'Respuesta repetida') {
                    setColor("error");
                    setOpenSnackBar(true);
                    setMsg("ERROR: " + intl.formatMessage({ id: "edit.survey.msg.question.add.error.duplicate.options" }));
                }
            } else {
                setColor("error");
                setOpenSnackBar(true);
                setMsg(intl.formatMessage({ id: "edit.survey.msg.question.error" }));
            }
            setFailedBtnColor(true);
            setLoading(false);
        }
    });

    const createSurveyMutation = useMutation({
        mutationFn: async ({ changes, values }) => {
            if (!loading) {
                setSuccessBtnColor(false);
                setFailedBtnColor(false);
                setLoading(true);
            }
            const response = await axiosPrivate.post(`question/add/${survey}?customer=${customerName}`, values)
            return response.data
        },
        onSuccess: (data) => {
            setSuccessBtnColor(true);
            setLoading(false);
            editQuestion.options = options;
            setOpenSnackBar(true);
            setColor("success");
            setMsg(intl.formatMessage({ id: "edit.survey.msg.question.add.success" }));
            queryClient.invalidateQueries(["surveyQuestions", survey], { exact: true });
            setCheckvalidationAfterBtn(false)
        },
        onError: (error) => {
            console.error(error);
            setCheckvalidationAfterBtn(false)
            //check the status
            if (error.response.status === 401) {
                if (error.response.data?.text === 'La pregunta ya existe') {
                    setColor("error");
                    setOpenSnackBar(true);
                    setMsg("ERROR: " + intl.formatMessage({ id: "edit.survey.msg.question.add.error.duplicate" }));
                } else if (error.response.data?.text === 'Respuesta repetida') {
                    setColor("error");
                    setOpenSnackBar(true);
                    setMsg("ERROR: " + intl.formatMessage({ id: "edit.survey.msg.question.add.error.duplicate.options" }));
                }
            } else {
                setColor("error");
                setOpenSnackBar(true);
                setMsg(intl.formatMessage({ id: "edit.survey.msg.question.add.error" }));
            }
            setFailedBtnColor(true);
            setLoading(false);
        }
    });

    const [qType, setQType] = useState("0");//El warning es por el valor por defecto
    const [order, setOrder] = useState(0);
    const [options, setOptions] = useState([]);
    const [questionText, setQuestionText] = useState("");
    const [requiredQuestion, setRequiredQuestion] = useState(false);
    const [enabledQuestion, setEnabledQuestion] = useState(false);
    const [itemType, setItemType] = useState("");
    const [itemClass, setItemClass] = useState("0");
    const [valueFormat, setValueFormat] = useState("");
    const [minNumber, setMinNumber] = useState("");
    const [maxNumber, setMaxNumber] = useState("");
    const [maxLength, setMaxLength] = useState("");
    const [emojisChecked, setEmojisChecked] = useState(false);
    const [emojiPack, setEmojiPack] = useState(1);
    const [validationErrors, setValidationErrors] = useState({});
    const [color, setColor] = useState("success");
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [msg, setMsg] = useState('');
    const msgTime = 3000;
    const [checkvalidationAfterBtn, setCheckvalidationAfterBtn] = useState(false);
    const language = useContext(languageContext);
    const [subtitle, setSubtitle] = useState("");

    useEffect(() => {
        questionTypes && setQType(questionTypes?.find(type => type.type === editQuestion?.item_type)?.id)
        if (editQuestion?.options) {
            editQuestion[optionsLabelLanguage[language.locale]]?.length > 1 ?
                setOptions(editQuestion[optionsLabelLanguage[language.locale]]) :
                setOptions(editQuestion.options)
            editQuestion[itemLabelLanguage[language.locale]]?.length > 1 ?
                setQuestionText(editQuestion[itemLabelLanguage[language.locale]]) :
                setQuestionText(editQuestion.item_label)
        }
        editQuestion?.item_order && setOrder(editQuestion.item_order)
        editQuestion?.required ? setRequiredQuestion(editQuestion.required) : setRequiredQuestion(false)
        editQuestion?.enabled && setEnabledQuestion(editQuestion.enabled)
        editQuestion?.question_type && setItemType(editQuestion.question_type)
        editQuestion?.item_class && itemClasses?.find(c => (c.label_es === editQuestion?.item_class)) ?
            setItemClass(itemClasses?.find(c => c.label_es === editQuestion?.item_class)?.id) :
            setItemClass("0")
        editQuestion?.subtitle !== null && setSubtitle(language.locale === "es" ? editQuestion?.subtitle : editQuestion?.["subtitle_" + language.locale])
        if (editQuestion?.pack_name) {
            setEmojisChecked(true);
            //get the number of the pack
            const packNumber = editQuestion.pack_name.split(" ")[1]
            setEmojiPack(packNumber)
        } else {
            setEmojisChecked(false)
            setEmojiPack(1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionTypes, editQuestion, itemClasses])

    useEffect(() => {
        if (editQuestion?.item_label) {
            editQuestion[optionsLabelLanguage[language.locale]]?.length > 1 ?
                setOptions(editQuestion[optionsLabelLanguage[language.locale]]) :
                setOptions(editQuestion.options)
            editQuestion[itemLabelLanguage[language.locale]]?.length > 1 ?
                setQuestionText(editQuestion[itemLabelLanguage[language.locale]]) :
                setQuestionText(editQuestion.item_label)
            editQuestion?.subtitle !== null && setSubtitle(language.locale === "es" ? editQuestion?.subtitle : editQuestion?.["subtitle_" + language.locale])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl])

    useEffect(() => {
        setValueFormat(questionValuesFormat?.find(type => type.label === editQuestion?.value_format))
        if (editQuestion?.min_number) {
            setMinNumber(editQuestion.min_number)
        }
        if (editQuestion?.max_number) {
            setMaxNumber(editQuestion.max_number)
        }
        setMaxLength(editQuestion?.create ? "" : editQuestion?.max_length ? editQuestion?.max_length : "")
    }, [questionValuesFormat, editQuestion, qType])

    useEffect(() => {
        if (checkvalidationAfterBtn) {
            const validations = checkvValidations()
            setValidationErrors(validations)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, questionText, order, maxLength, checkvalidationAfterBtn, qType])

    const handleClose = () => {
        setOpenModal(false)
        setCheckClickBtn(false)
        // if (window.confirm("Estas seguro que deseas salir? Es posible que los cambios realizados no se guarden")) setOpenModal(false)
    };

    const handleDelete = (e) => {
        let { id } = e.target
        //check if the target is the delete icon
        if (!id) {
            //go up one level to get the id
            const { parentNode } = e.target
            id = parentNode.id
        }
        //remove the index from the array
        const newOptions = options.filter((option, index) => index !== parseInt(id))
        setOptions(prev => {
            return newOptions
        })
    }

    const handleAddOption = () => {
        if (options[0] !== null) {
            setOptions(prev => {
                return [...prev, ""]
            })
        } else {
            setOptions(prev => {
                return [""]
            })
        }
    }

    const handleOptionChange = (e) => {
        const { id, value } = e.target
        const newOptions = options.map((option, index) => {
            if (index === parseInt(id)) {
                return value
            }
            return option
        });
        setOptions(prev => {
            return newOptions
        })
    }

    const [loading, setLoading] = useState(false);
    const [successBtnColor, setSuccessBtnColor] = useState(false);
    const [failedBtnColor, setFailedBtnColor] = useState(false);
    const timer = useRef();

    useEffect(() => {
        //reset successBtnColor after 3 seconds
        if (successBtnColor) {
            timer.current = setTimeout(() => {
                setSuccessBtnColor(false);
            }
                , msgTime);
        }
        if (failedBtnColor) {
            timer.current = setTimeout(() => {
                setFailedBtnColor(false)
            }
                , msgTime);
        }
        return () => {
            clearTimeout(timer.current);
        }
    }, [successBtnColor, failedBtnColor]);

    const buttonSx = {
        ...(successBtnColor && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }), ...(failedBtnColor && {
            bgcolor: red[500],
            '&:hover': {
                failedBtnColor: red[700],
            },
        })
    };

    const validateChanges = () => {
        const checkText = questionText === editQuestion[itemLabelLanguage[language.locale]]
        //check if the options are equal
        if (radioOptions.find(q => q === qType) || checkOptions.find(q => q === qType)) {
            if (options.length !== editQuestion[optionsLabelLanguage[language.locale]]?.length) {
                return [
                    checkText,
                    false
                ]
            } else {
                return [
                    checkText,
                    options.every((option, index) => option === editQuestion[optionsLabelLanguage[language.locale]][index])
                ]
            }
        }
        else if (checkText) {
            return [true, false]
        } else {
            return [checkText, false]
        }
    }

    const checkvValidations = () => {
        const errors = {
            textField: "",
            order: "",
            options: "",
            maxLength: "",
        };
        const charLength = 500;
        //check if the question text is empty
        if (order < 0) {
            errors.order = (intl.formatMessage({ id: "edit.survey.label.order.error" }));
        }
        if (questionText === "") {
            errors.textField = (intl.formatMessage({ id: "edit.survey.label.question.text.error" }));
        }
        if (questionText.length > charLength) {
            errors.textField = (
                <FormattedMessage
                    id="edit.survey.label.question.text.error.length"
                    values={{ charLength }}
                />
            );
        }
        if (radioOptions.find(q => q === qType) || checkOptions.find(q => q === qType)) {
            if (qType !== "2" && !emojisChecked) {
                if (options.length === 1) {
                    errors.options = (intl.formatMessage({ id: "edit.survey.one.option.error" }));
                }
            }
            //check if the options are empty
            if (options.length === 0 && !emojisChecked) {
                errors.options = (intl.formatMessage({ id: "edit.survey.label.question.options.empty.options" }));
            }
            //check is there is an empty option
            if (options.some(option => option === "")) {
                errors.options = (intl.formatMessage({ id: "edit.survey.label.question.options.empty.option" }));
            }
            //TODO: Se puede mejorar si se colocan los errores por separado
            //check if there are duplicated options
            if (options.length !== new Set(options).size) {
                //get the values of the duplicated options
                const duplicatedOptions = options.filter((option, index, self) => self.indexOf(option) !== index)
                //remove the empty options
                if (duplicatedOptions[0] !== "") {
                    setDuplicatedOptionValue(duplicatedOptions[0])
                    errors.options = ("Respuesta repetida");
                } else {
                    setDuplicatedOptionValue()
                }
            } else {
                setDuplicatedOptionValue()
            }
        }
        if (textQuestion.find(q => q === qType) && parseInt(maxLength) < 0) {
            errors.maxLength = intl.formatMessage({ id: "edit.survey.label.question.text.box.max.lenght.restrictions" });
        } else if ("1" === qType && parseInt(maxLength) > maxCharsTextArea) {
            errors.maxLength = "El maximo de caracteres permitidos es 150";
        } else if ("3" === qType && parseInt(maxLength) > maxCharsTextBox) {
            errors.maxLength = "El maximo de caracteres permitidos es 70";
        }
        return errors
    }

    const [checkClickBtn, setCheckClickBtn] = useState(false)
    const [duplicatedOptionValue, setDuplicatedOptionValue] = useState(null)

    const handleEmojiCheck = () => {
        let newOptions = []
        const aditionalOptions = options.filter(option => !acceptedEmojiAnswers.includes(option))
        if (emojisChecked) {
            for (let i = 5; i >= 1; i--) {
                newOptions.push(i.toString())
            }
            aditionalOptions.map((option) => {
                return newOptions.push(option)
            })
            setOptions(newOptions)
        }
        setCheckClickBtn(true)
        return newOptions
    }

    const checkEmojiPackMoreOptions = () => {
        if (emojisChecked) {
            //get the elements from the fifth element
            const newOptionsEmoji = editQuestion?.create ? options : options.slice(5, options.length)
            const errorEmojiPack = newOptionsEmoji.filter(option => acceptedEmojiAnswers.includes(option))
            if (errorEmojiPack.length !== 0) {
                let msg = "Opciones no validas, por favor elimine las opciones: " + errorEmojiPack.join(", ")
                // Find the last index of ","
                let lastIndex = msg.lastIndexOf(',');
                // If there is at least one occurrence of ",", replace it with "y"
                if (lastIndex !== -1) {
                    msg = msg.slice(0, lastIndex) + ' y' + msg.slice(lastIndex + 1);
                }
                return msg;
            }
        }
        return null
    }

    const handleUpdateButton = async () => {
        try {
            setCheckvalidationAfterBtn(true)
            const validations = checkvValidations()
            setValidationErrors(validations)
            const changes = validateChanges()
            const errorEmojiPack = checkEmojiPackMoreOptions();
            //check if there are any errors
            if (errorEmojiPack) {
                setOpenSnackBar(true);
                setColor("error");
                setMsg(errorEmojiPack);
                return null
            }
            if (Object.values(validations).some(error => error !== "")) {
                setOpenSnackBar(true);
                setColor("error");
                setMsg(intl.formatMessage({ id: "edit.survey.fields.errors" }));
                return null
            }
            const newOptions = handleEmojiCheck()
            const values = {
                order,
                enabled: enabledQuestion,
                required: requiredQuestion,
                item_type: qType,
                question_type: itemType,
                subtitle: editQuestion[subtitleLanguage[language.locale]] === subtitle ? null : subtitle,
                survey_emoji_id: emojisChecked ? emojiPack : null,
                item_class_id: (itemClass === 0 || itemClass === "0") ? null : itemClass,
                options: emojisChecked ? newOptions : changes[1] ? null : options,
                item_label: changes[0] ? null : questionText,
                value_format_id: textQuestion.find(q => q === qType) && valueFormat?.id ? valueFormat?.id : null,
                min_number: textQuestion.find(q => q === qType) && valueFormat?.id === "2" && minNumber !== "" ? minNumber : null,
                max_number: textQuestion.find(q => q === qType) && valueFormat?.id === "2" && maxNumber !== "" ? maxNumber : null,
                max_length: qType === "1" && maxLength !== "" ? maxLength : valueFormat?.id && maxLength !== "" ? maxLength : null,
                language: language.locale
            }
            updateSurveyMutation.mutate({ changes, values })
        } catch (err) {
            console.error(err)
        }
    };

    const handleCreateButton = async () => {
        try {
            if (validateQuestionLimitation()) return;
            setCheckvalidationAfterBtn(true)
            const validations = checkvValidations()
            setValidationErrors(validations)
            const changes = validateChanges()
            const errorEmojiPack = checkEmojiPackMoreOptions();
            //check if there are any errors
            if (errorEmojiPack) {
                setOpenSnackBar(true);
                setColor("error");
                setMsg(errorEmojiPack);
                return null
            }
            if (Object.values(validations).some(error => error !== "")) {
                setOpenSnackBar(true);
                setColor("error");
                setMsg(intl.formatMessage({ id: "edit.survey.fields.errors" }));
                return null
            }
            const newOptions = handleEmojiCheck();
            const values = {
                order,
                enabled: enabledQuestion,
                required: requiredQuestion,
                item_type: qType,
                question_type: itemType,
                subtitle: editQuestion[subtitleLanguage[language.locale]] === subtitle ? null : subtitle,
                survey_emoji_id: emojisChecked ? emojiPack : null,
                item_class_id: (itemClass === 0 || itemClass === "0") ? null : itemClass,
                options: emojisChecked ? newOptions : changes[1] ? null : options,
                item_label: changes[0] ? null : questionText,
                value_format_id: textQuestion.find(q => q === qType) && valueFormat?.id ? valueFormat?.id : null,
                min_number: textQuestion.find(q => q === qType) && valueFormat?.id === "2" && minNumber !== "" ? minNumber : null,
                max_number: textQuestion.find(q => q === qType) && valueFormat?.id === "2" && maxNumber !== "" ? maxNumber : null,
                max_length: maxLength !== "" ? maxLength : null,
                language: language.locale
            }
            createSurveyMutation.mutate({ changes, values })
        } catch (err) {
            console.error(err)
        }
    };

    return (
        <>
            <SnackBarComponent
                position={topCenterPosition}
                transition={TransitionDown}
                handleClose={(event, reason) => {
                    if (reason === 'clickaway') {
                        return;
                    }
                    setOpenSnackBar(false);
                }}
                time={msgTime}
                openSnackBar={openSnackBar}
                color={color}
                msg={msg}
            />
            <ScrollToTopComponent />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box className='editQuestionModal' sx={style}>
                        <div className=''>
                            <div className='row'>
                                <div className='text-center'>
                                    <MainDialogTitle id="transition-modal-title" onClose={handleClose}>
                                        {editQuestion?.create ?
                                            <FormattedMessage
                                                id="edit.survey.question.title.create"
                                                defaultMessage={`Crear pregunta`}
                                            />
                                            :
                                            <FormattedMessage
                                                id="edit.survey.question.title.edit"
                                                defaultMessage={`Editar pregunta`}
                                            />
                                        }
                                    </MainDialogTitle>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 mt-1'>
                                    <FormControl className='orderQuestion mt-3'>
                                        <TextField
                                            sx={{ width: validationErrors?.order ? "160px" : "70px" }}
                                            error={validationErrors?.order ? true : false}
                                            maxRows={4}
                                            type="number"
                                            min="1"
                                            max="5"
                                            id="inputQuestion"
                                            label={intl.formatMessage({ id: "edit.survey.label.order" })}
                                            onChange={(e) => setOrder(e.target.value)}
                                            defaultChecked={""}
                                            value={order}
                                            helperText={validationErrors?.order ? validationErrors.order : ""}
                                        />
                                    </FormControl>
                                    {
                                        isLoadiongItemTypes ?
                                            <LoadingInputs />
                                            :
                                            // Para que no pueda seleccionar cuando sea dependiente
                                            editQuestion?.question_type !== 4 &&
                                            <FormControl className='mt-3' sx={{ minWidth: 120, marginRight: "10px" }}>
                                                <InputLabel id="itemType">
                                                    <FormattedMessage
                                                        id="edit.survey.label.question.priority"
                                                        defaultMessage={`Tipo de pregunta`}
                                                    />
                                                </InputLabel>
                                                <Select
                                                    labelId="itemType"
                                                    id="itemTypeSelect"
                                                    value={itemType}
                                                    onChange={(e) => setItemType(e.target.value)}
                                                    autoWidth
                                                    label={intl.formatMessage({ id: "edit.survey.label.question.priority" })}
                                                >
                                                    {
                                                        itemTypes ?
                                                            itemTypes.map((type, index) => (
                                                                type.label !== "voucher" &&
                                                                <MenuItem key={index} value={type.id}>
                                                                    <Tooltip placement="right" arrow title={QUESTION_ITEM_NAME[type.label]?.description}>
                                                                        <span>
                                                                            {QUESTION_ITEM_NAME[type.label]?.name ?? type.label}
                                                                        </span>
                                                                    </Tooltip>
                                                                </MenuItem>
                                                            ))
                                                            :
                                                            <MenuItem style={{ color: "red" }} key={"error"}>Error</MenuItem>
                                                    }
                                                </Select>
                                            </FormControl>
                                    }
                                    {
                                        isLoadingQuestionTypes ?
                                            <LoadingInputs />
                                            :
                                            <FormControl sx={{ minWidth: 120, marginRight: "10px" }} className='mt-3'>
                                                <InputLabel id="QuestionType">
                                                    <FormattedMessage
                                                        id="edit.survey.label.question.type"
                                                        defaultMessage={`Tipo de pregunta`}
                                                    />
                                                </InputLabel>
                                                <Select
                                                    labelId="QuestionType"
                                                    id="QuestionTypeSelect"
                                                    value={qType}
                                                    onChange={(e) => {
                                                        if (e.target.value === "6") {
                                                            setEmojisChecked(false)
                                                        }
                                                        setQType(e.target.value)
                                                    }}
                                                    autoWidth
                                                    label={intl.formatMessage({ id: "edit.survey.label.question.type" })}
                                                >
                                                    {questionTypes ?
                                                        questionTypes.map((type, index) => (
                                                            <MenuItem key={index} value={type.id}>
                                                                <Tooltip placement="right" arrow title={QUESTIONS_TYPE_LIST[type.type]["description"]}>
                                                                    <span>
                                                                        {QUESTIONS_TYPE_LIST[type.type].name}
                                                                    </span>
                                                                </Tooltip>
                                                            </MenuItem>
                                                        ))
                                                        :
                                                        <MenuItem style={{ color: "red" }} key={"error"} >Error</MenuItem>
                                                    }
                                                </Select>
                                            </FormControl>
                                    }
                                    {
                                        isLoadingItemClasses ?
                                            <LoadingInputs />
                                            :
                                            <FormControl className='mt-3' sx={{ minWidth: 170 }}>
                                                <InputLabel id="itemClass">
                                                    <FormattedMessage
                                                        id="edit.survey.label.question.category"
                                                        defaultMessage={`Categoria de la pregunta`}
                                                    />
                                                </InputLabel>
                                                <Select
                                                    labelId="itemClass"
                                                    id="itemClassSelect"
                                                    value={itemClass}
                                                    onChange={(e) => setItemClass(e.target.value)}
                                                    autoWidth
                                                    label={intl.formatMessage({ id: "edit.survey.label.question.category" })}
                                                >
                                                    {
                                                        itemClasses ?
                                                            itemClasses?.map((type, index) => (
                                                                <MenuItem key={index} value={type.id}>{type[`label_${language.locale}`]}</MenuItem>
                                                            ))
                                                            :
                                                            <MenuItem style={{ color: "red" }} key={"error"}>Error</MenuItem>
                                                    }
                                                </Select>
                                            </FormControl>
                                    }
                                </div>
                                <div className='container mt-2'>
                                    {
                                        isErrorItemClasses &&
                                        <h6 style={{ color: "red" }}>
                                            <FormattedMessage
                                                id="edit.survey.error.loading.question.category"
                                                defaultMessage={`Error al cargan las categorias de las preguntas`}
                                            />
                                        </h6>
                                    }
                                    {
                                        isErrorItemTypes &&
                                        <h6 style={{ color: "red" }}>
                                            <FormattedMessage
                                                id="edit.survey.error.loading.question.priority"
                                                defaultMessage={`Error al cargan la prioridad de las preguntas`}
                                            />
                                        </h6>
                                    }
                                    {
                                        isErrorQuestionTypes &&
                                        <h6 style={{ color: "red" }}>
                                            <FormattedMessage
                                                id="edit.survey.error.loading.question.type"
                                                defaultMessage={`Error al cargan los tipos de preguntas`}
                                            />
                                        </h6>
                                    }
                                </div>
                            </div>
                        </div>
                        <EditQuestionText
                            questionText={questionText}
                            setQuestionText={setQuestionText}
                            validationErrors={validationErrors}
                        />
                        <TextField sx={{ mt: 2 }}
                            fullWidth
                            maxRows={4}
                            multiline
                            id="inputSubtitle"
                            label={"Subtitulo (opcional)"}
                            defaultChecked={""}
                            placeholder='Ejemplo: "Seleccione una opción", "Siendo 1 malo y 5 excelente"'
                            value={subtitle}
                            onChange={(e) => setSubtitle(e.target.value)}
                        />
                        <div className='mt-2'>
                            {radioOptions.find(q => q === qType) &&
                                <>
                                    <div className='row'>
                                        <div className='col-md-2 mt-2'>
                                            <b>
                                                <FormattedMessage
                                                    id="edit.survey.label.question.options"
                                                    defaultMessage={`Opciones`}
                                                />
                                            </b>
                                        </div>
                                        {forRadioEmojis.find(q => q === qType) &&
                                            <div className='col-md-10'>
                                                <FormControlLabel
                                                    className='questionTypeFromControl'
                                                    control={
                                                        <Switch
                                                            onChange={(e) => { setEmojisChecked(e.target.checked) }}
                                                            checked={emojisChecked}
                                                        />
                                                    }
                                                    label={intl.formatMessage({ id: "edit.survey.label.question.options.emojis" })}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {
                                        forRadioEmojis.find(q => q === qType) && emojisChecked ?
                                            <>
                                                <FormControl className='mt-2' sx={{ minWidth: 220, }}>
                                                    <InputLabel id="Emojis">Emojis</InputLabel>
                                                    <Select
                                                        labelId="emojis"
                                                        id="emojisSelect"
                                                        autoWidth
                                                        value={emojiPack}
                                                        onChange={(e) => setEmojiPack(e.target.value)}
                                                        label="Tipo de pregunta"
                                                    >
                                                        {emojis?.map((type, index) => (
                                                            <MenuItem key={index} value={type.id}>
                                                                {
                                                                    Array.from(Array(emojis.length).keys()).map((i) => (
                                                                        <img alt={1} key={i} className="emoji" src={`https://storage.googleapis.com/server-7bytes.appspot.com/Emoji%20Packs/Pack%20${type.id}/Pack%20${type.id}.${5 - i}.png`} />
                                                                    ))
                                                                }
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <div className='mt-2'>
                                                    <EditRadioOptions
                                                        options={options}
                                                        handleOptionChange={handleOptionChange}
                                                        handleDelete={handleDelete}
                                                        handleAddOption={handleAddOption}
                                                        validationErrors={validationErrors}
                                                        emojisChecked={emojisChecked}
                                                        create={editQuestion?.create}
                                                        checkClickBtn={checkClickBtn}
                                                        duplicatedOptionValue={duplicatedOptionValue}
                                                    />
                                                </div>
                                            </>
                                            :
                                            <EditRadioOptions
                                                options={options}
                                                handleOptionChange={handleOptionChange}
                                                handleDelete={handleDelete}
                                                handleAddOption={handleAddOption}
                                                validationErrors={validationErrors}
                                                emojisChecked={false}
                                                duplicatedOptionValue={duplicatedOptionValue}
                                            />
                                    }
                                </>
                            }
                            {checkOptions.find(q => q === qType) &&
                                <>
                                    <h6>
                                        <FormattedMessage
                                            id="edit.survey.label.question.options"
                                            defaultMessage={`Opciones`}
                                        />
                                    </h6>
                                    <EditCheckOptions
                                        options={options}
                                        handleOptionChange={handleOptionChange}
                                        handleDelete={handleDelete}
                                        handleAddOption={handleAddOption}
                                        validationErrors={validationErrors}
                                        duplicatedOptionValue={duplicatedOptionValue}
                                    />
                                </>
                            }
                            {textQuestion.find(q => q === qType) &&
                                <>
                                    <b style={{ marginTop: "15px" }}>
                                        <FormattedMessage
                                            id="edit.survey.label.question.text.box.restrictions"
                                            defaultMessage={`Restricciones para el campo de texto (Opcional).`}
                                        />
                                    </b>
                                    <i>
                                        <FormattedMessage
                                            id="edit.survey.label.question.text.box.restrictions.default"
                                            defaultMessage={`Por defecto acepta todo sin ninguna restriccion`}
                                            values={{ charLength: qType === "3" ? maxCharsTextBox : maxCharsTextArea }}
                                        />
                                    </i>
                                    <div className='inline row '>
                                        {qType === "3" &&
                                            <>
                                                {
                                                    isLoadingQuestionValuesFormat ?
                                                        <FormControl className='mt-3 container' sx={{ width: 180, marginLeft: "10px" }}>
                                                            <LoadingInputs />
                                                        </FormControl>
                                                        :
                                                        questionValuesFormat &&
                                                        <Autocomplete
                                                            className='mt-3'
                                                            disablePortal
                                                            id="valueformat"
                                                            value={valueFormat ? valueFormat : null}
                                                            onChange={(event, newValue) => {
                                                                setValueFormat(newValue);
                                                            }}
                                                            options={questionValuesFormat}
                                                            renderOption={(props, option) => (
                                                                <li {...props}>
                                                                    {valueFormatNames[option.id]}
                                                                </li>
                                                            )}
                                                            sx={{ maxWidth: 250 }}
                                                            getOptionLabel={(option) => {
                                                                return valueFormatNames[option.id]
                                                            }}
                                                            renderInput={(params) => <TextField {...params} label={
                                                                <FormattedMessage
                                                                    id="edit.survey.label.question.text.box.restrictions.label"
                                                                />
                                                            } />}
                                                        />
                                                }
                                                {
                                                    isErrorQuestionValuesFormat &&
                                                    <h6 className='mt-2' style={{ color: "red" }}>
                                                        <FormattedMessage
                                                            id="edit.survey.error.loading.value.format"
                                                            defaultMessage={`Error al cargar los valores permitidos`}
                                                        />
                                                    </h6>
                                                }
                                                {valueFormat?.id === "2" &&
                                                    <>
                                                        <TextField
                                                            className='mt-3'
                                                            sx={{ width: "140px", marginLeft: "10px" }}
                                                            error={false}
                                                            type="number"
                                                            id="min"
                                                            value={minNumber}
                                                            onChange={(e) => setMinNumber(e.target.value)}
                                                            label={intl.formatMessage({ id: "edit.survey.label.question.text.box.restrictions.min" })}
                                                            helperText={false ? "Incorrect entry." : ""}
                                                        />
                                                        <TextField
                                                            className='mt-3'
                                                            sx={{ width: "140px", marginLeft: "10px" }}
                                                            error={false}
                                                            type="number"
                                                            id="Max"
                                                            value={maxNumber}
                                                            onChange={(e) => setMaxNumber(e.target.value)}
                                                            label={intl.formatMessage({ id: "edit.survey.label.question.text.box.restrictions.max" })}
                                                            helperText={false ? "Incorrect entry." : ""}
                                                        />
                                                    </>
                                                }
                                            </>
                                        }
                                        {(valueFormat?.id === "1" || valueFormat?.id === "3" || qType === "1") &&
                                            <>
                                                <TextField
                                                    className='mt-3'
                                                    sx={{ width: "220px", marginLeft: "10px" }}
                                                    error={validationErrors?.maxLength ? true : false}
                                                    type="number"
                                                    id="maxLength"
                                                    minRows={4}
                                                    value={maxLength}
                                                    onChange={(e) => setMaxLength(e.target.value)}
                                                    label={intl.formatMessage({ id: "edit.survey.label.question.text.box.restrictions.length" })}
                                                    helperText={validationErrors?.maxLength ? validationErrors.maxLength : ""}
                                                />
                                            </>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                        {editQuestion?.create ?
                            <Box className='text-center mt-3' sx={{ m: 1, position: 'relative', }}>
                                <Button
                                    variant="contained"
                                    sx={buttonSx}
                                    disabled={loading}
                                    onClick={handleCreateButton}
                                >
                                    <FormattedMessage
                                        id="edit.survey.btn.create"
                                        defaultMessage={`Crear nueva pregunta`}
                                    />
                                </Button>
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            left: '48%',
                                            marginTop: '6px',
                                        }}
                                    />
                                )}
                            </Box>
                            :
                            <Box className='text-center mt-3' sx={{ m: 1, position: 'relative', }}>
                                <Button
                                    variant="contained"
                                    sx={buttonSx}
                                    color='warning'
                                    disabled={loading}
                                    onClick={handleUpdateButton}
                                >
                                    <FormattedMessage
                                        id="edit.survey.question.update.btn"
                                        defaultMessage={`Actualizar pregunta`}
                                    />
                                </Button>
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            left: '48%',
                                            marginTop: '6px',
                                        }}
                                    />
                                )}
                            </Box>
                        }
                    </Box>
                </Fade>
            </Modal >
        </>
    );
}
