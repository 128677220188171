import { Autocomplete, TextField } from '@mui/material';

export const SelectRadioQuestion = ({ index, question, itemLabel, optionLabel }) => {
    return (
        <>
            
            <Autocomplete
                disablePortal
                id={`selectradio${(index + 1)}${(question.id)}`}
                options={question[optionLabel]}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Seleccione una opcion" />}
            />
        </>
    )
}