import { Autocomplete, TextField } from '@mui/material'

export const SelectCheckQuestion = ({ index, question, itemLabel, optionLabel }) => {
    return (
        <>
            
            <Autocomplete
                className='mt-2'
                multiple
                limitTags={2}
                options={question[optionLabel]}
                getOptionLabel={opcion => opcion}
                id={`selectcheck${(index + 1)}${(question.id)}`}
                // defaultValue={}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label=""
                        placeholder="Seleccione una o varias opciones"
                    />
                )}
            />
        </>
    )
}