import { Autocomplete, TextField } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useSurveyContext } from '../../Context/SurveyCustomHooks/useSurveyContext';
import { languageContext } from '../../../../../context/languageContext';
import { useIntl } from 'react-intl';

export const SelectRadioQuestion = () => {
    const intl = useIntl()
    const language = useContext(languageContext);
    const {
        answers,
        activeStep,
        setAnswers,
        updateAnswers,
        isSmallScreen,
        surveyQuestions,
        getLanguageOptions,
        setEnableScrollMove,
        handleNext,
    } = useSurveyContext();
    const question = surveyQuestions[activeStep];
    const options = getLanguageOptions(question, language.locale, "select");
    const [selectedValue, setSelectedValue] = React.useState(answers.find(answer => answer.survey_item_id === question.itemId)?.answer_value ?? null);

    useEffect(() => {
        //* !Si cambian los idiomas aparece un warning diciendo que la respuesta anterior no se encuentra en las opciones
        //get the answer value of the question id(Solo campoara en español porque solo se guarda en español) 
        const answer = answers.find(answer => answer.survey_item_id === question.itemId)?.answer_value;
        const index = question.options.findIndex(option => option === answer);
        if (answer) {
            //Check question?.[`options${language.locale} has any null value
            const checkNull = question?.[`options${language.locale}`]?.filter(option => option === null).length > 0;
            language.locale !== "es" && !checkNull ? setSelectedValue(question?.[`options${language.locale}`][index]) :
                setSelectedValue(answer);
        }
        //eslint-disable-next-line
    }, [intl])

    const handleRadioChange = (e, value) => {
        setSelectedValue(value);
        if (value === null) {
            setAnswers(answers.filter(answer => answer.survey_item_id !== question.itemId));
            return;
        }
        const index = options.findIndex(option => option === value);
        //Guarda solo la opcion en español
        updateAnswers(question.itemId, question.options[index]);
        handleNext(true);
    }

    return (
        <Autocomplete
            className='center RegularMadeTommy'
            disablePortal
            id={`selectradio${activeStep}$`}
            options={options}
            sx={{ minWidth: 160, width: isSmallScreen ? '100%' : '60%', borderTop: 'none' }}
            onOpen={() => setEnableScrollMove(false)} // Se activa cuando el autocomplete se abre
            onClose={() => setEnableScrollMove(true)} // Se activa cuando el autocomplete se cierra
            value={selectedValue}
            onChange={handleRadioChange}
            renderOption={(props, option, { selected }) => {
                return (
                    <li
                        {...props}
                        className="RegularMadeTommy"
                        style={{
                            textAlign: "left",
                            padding: isSmallScreen ? "8px 20px" : "5px 20px",//Separacion de las opciones
                            position: "relative",
                        }}
                    >
                        <input
                            type="radio"
                            style={{
                                margin: "0px ",
                                position: "absolute",
                                left: "0px",
                                top: "50%",
                                transform: "translateY(-50%)",
                            }}
                            checked={selected}
                            readOnly
                        />
                        {option ? option : question.options[props['data-option-index']]}
                    </li>
                )
            }}
            ListboxProps={{
                'aria-labelledby': 'demo-dialog-autocomplete-label',
                style: { padding: '0px 10px', maxHeight: "200px", overflowY: "auto" }
            }}
            // defaultValue={answers.find(answer => answer.survey_item_id === question.itemId)?.answer_value}
            renderInput={(params) =>
                // el div se hace porque cuando el fondo no es blanco la letra se sale del input y no se ve
                //padding: (inputClick || value) ? '8px 0px 0px 0px' : '0px'
                <div style={{ backgroundColor: 'white', borderRadius: '10px', }}>
                    <TextField
                        {...params}
                        // onFocus={() => setInputClick(true)}
                        // onBlur={() => setInputClick(false)}
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: 'none', // Elimina el borde del campo
                                }
                            },
                        }}
                        label={intl.formatMessage({ id: "survey.error.selectOption" })}
                        className="MediumMadeTommy"
                    />
                </div>
            }
        />
    )
}
