import { IconButton, Tooltip } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import React, { useContext, useState } from 'react'
import StylesContext from './SytlesContextViewSurvey';
import { useIntl } from 'react-intl';
import useAuth from '../../../CustomHooks/useAuth';
import useAxiosPrivate from '../../../CustomHooks/useAxiosPrivate';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { SnackBarComponent } from '../../UtilsComponents/SnackBarComponent';
import { topCenterPosition, TransitionDown } from '../../UtilsComponents/SnackBarTransitions';

export const SaveChangesViewSurvey = () => {
    const {
        source,
        stylesToCompare,
        submitLogo,
        btnColorBackground,
        btnColorText,
        surveySubtitleColor,
    } = useContext(StylesContext);
    const axiosPrivate = useAxiosPrivate();
    const { defaultCustomer } = useAuth();
    const intl = useIntl();
    const customerName = defaultCustomer.toLowerCase();
    const [sending, setSending] = useState(false);
    const [color, setColor] = useState("success");
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [msg, setMsg] = useState('');
    //get query params
    const surveyId = new URLSearchParams(window.location.search).get('survey');
    const validations = () => {
        const formData = new FormData();
        if (document.getElementById('input-source').value !== source) {
            formData.append("source", document.getElementById('input-source').value);
        }
        if (submitLogo !== stylesToCompare?.customerImg) {
            formData.append("customerImg", submitLogo);
        }
        if (document.getElementById('input-subtitle').value !== stylesToCompare?.subtitle &&
            document.getElementById('input-subtitle').value !== intl.formatMessage({ id: "styles.change.subtitle" })) {
            formData.append("sub_title", document.getElementById('input-subtitle').value);
        }
        if (surveySubtitleColor !== stylesToCompare?.subtitleColor) {
            formData.append("sub_title_color", surveySubtitleColor);
        }
        if (btnColorBackground !== stylesToCompare?.btnSubmit.bg) {
            formData.append("btn_back_color", btnColorBackground);
        }
        if (btnColorText !== stylesToCompare?.btnSubmit.color) {
            formData.append("btn_font_color", btnColorText);
        }
        return formData;
    };

    const handleSubmit = async () => {
        try {
            setSending(true);
            const formData = validations();
            if (formData.keys().next().done) {
                setColor("warning");
                setMsg(intl.formatMessage({ id: "styles.no.changes" }));
                setOpenSnackBar(true);
                return;
            }
            const entriesArray = Array.from(formData.keys())
            if (!window.confirm(intl.formatMessage({ id: "styles.confirm.save" }))) return;
            
            if (formData.get('source')) {
                if (!window.confirm(intl.formatMessage({ id: "styles.alert.source" }))) return;
                const surveyResponse = await axiosPrivate.put(`survey/${surveyId}?customer=${customerName}`,
                    {
                        source: formData.get('source')
                    }
                );
                if (entriesArray.length === 1) {
                    if (surveyResponse.status === 200) {
                        setColor("success");
                        setMsg(intl.formatMessage({ id: "styles.update.success" }));
                        setOpenSnackBar(true);
                    }
                    return;
                }
            }
            const controller = new AbortController();
            const headers = {
                "Content-Type": "multipart/form-data"
            }
            const response = await axiosPrivate.post(`customer/info?name=${customerName}`,
                formData,
                headers,
                {
                    signal: controller.signal,
                }
            );
            if (response.status === 200) {
                setColor("success");
                setMsg(intl.formatMessage({ id: "styles.update.success" }));
                setOpenSnackBar(true);
            }
        } catch (err) {
            console.error(err);
            if (err.response?.status === 400) {
                setMsg(intl.formatMessage({ id: "sytles.add.image" }));
            } else {
                setMsg(intl.formatMessage({ id: "styles.update.error" }));
            }
            setColor("error");
            setOpenSnackBar(true);
        }
        finally {
            setSending(false);
        }
    };

    return (
        (sending) ?
            // make and animation here
            <IconButton
                size="large"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
            >
                <HourglassBottomIcon className="animated-hourglass" />
            </IconButton>
            :
            <>
                <SnackBarComponent
                    position={topCenterPosition}
                    transition={TransitionDown}
                    handleClose={(event, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        setOpenSnackBar(false);
                    }}
                    time={5000}
                    openSnackBar={openSnackBar}
                    color={color}
                    msg={msg}
                />
                <Tooltip title={intl.formatMessage({ id: "styles.save.changes" })} arrow>
                    <IconButton
                        onClick={handleSubmit}
                        disabled={sending}
                        size="large"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <SaveIcon />
                    </IconButton>
                </Tooltip>
            </>
    )
}
